import { ItemCode } from './itemInfos';
import { Quest, QuestStep, QuestType, UnlockChallengeType, UnlockSteps } from './unlockModels';

export enum TimeLimitedQuestCode {
	Xmas2021,
	Xmas2022,
	Cny2023,
	AprilFool,
	Halloween2023,
	XmasPenguin,
	Cny2024,
	Halloween2024,
	ComingHalloween,
	ComingXmas,
	ComingCny,
	ComingAprilFool,
	PostHalloween,
	PostXmas,
	PostCny,
	PirateDay,
	ComingPirateDay,
	PostPirateDay,

	DecoSubmission,
	DecoVote,
	DecoWait,
}
const day = 24 * 60 * 60000;
export const eventTimes: Array<[number, number]> = [];
eventTimes[TimeLimitedQuestCode.XmasPenguin] = [1734192000000, 1738339199999]; // deprecated
eventTimes[TimeLimitedQuestCode.Halloween2023] = [1698508800000, 1699113599999]; // deprecated
eventTimes[TimeLimitedQuestCode.Cny2023] = [1674230400000, 1675180799999]; // deprecated


eventTimes[TimeLimitedQuestCode.Xmas2022] = [1734192000000, 1735660799999];  // 15/12/2024 ~ 31/12/2024
eventTimes[TimeLimitedQuestCode.Xmas2021] = eventTimes[TimeLimitedQuestCode.Xmas2022];
eventTimes[TimeLimitedQuestCode.AprilFool] = [1743505200000, 1743677940000]; // 31/3:1800 ~ 2/4 17:59 2025
eventTimes[TimeLimitedQuestCode.Halloween2024] = [1728921600000, 1730476799999]; // 15/10/2024 ~ 1/11/2024
eventTimes[TimeLimitedQuestCode.PirateDay] = [1726653600000, 1727776799999]; // 18/9:1800 ~ 1/10:1759
eventTimes[TimeLimitedQuestCode.Cny2024] = [1737475200000, 1737475200000 + 14 * day - 1]; // chor 1 - 7 days

eventTimes[TimeLimitedQuestCode.DecoSubmission] = [1730476800000, 1732118399999]; // 2/11/2024 ~ 20/11/2024
eventTimes[TimeLimitedQuestCode.DecoVote] = [eventTimes[TimeLimitedQuestCode.DecoSubmission][1] + 1, eventTimes[TimeLimitedQuestCode.DecoSubmission][1] + 5 * day];
eventTimes[TimeLimitedQuestCode.DecoWait] = [eventTimes[TimeLimitedQuestCode.DecoVote][1] + 1, eventTimes[TimeLimitedQuestCode.DecoVote][1] + 3 * day];

// const est = Date.now() + 20000;
// eventTimes[TimeLimitedQuestCode.DecoSubmission][0] = est;
// eventTimes[TimeLimitedQuestCode.DecoSubmission][1] = est + 10000 - 1;
// eventTimes[TimeLimitedQuestCode.DecoVote][0] = est + 10000;
// eventTimes[TimeLimitedQuestCode.DecoVote][1] = est + 20000 - 1;
// eventTimes[TimeLimitedQuestCode.DecoWait][0] = est + 20000;
// eventTimes[TimeLimitedQuestCode.DecoWait][1] = est + 30000;

// const comingSoon = 10000;
const comingSoon = 10 * day;
eventTimes[TimeLimitedQuestCode.ComingHalloween] = [
	eventTimes[TimeLimitedQuestCode.Halloween2024][0] - comingSoon,
	eventTimes[TimeLimitedQuestCode.Halloween2024][0] - 1,
];
eventTimes[TimeLimitedQuestCode.ComingXmas] = [
	eventTimes[TimeLimitedQuestCode.Xmas2022][0] - comingSoon,
	eventTimes[TimeLimitedQuestCode.Xmas2022][0] - 1,
];
eventTimes[TimeLimitedQuestCode.ComingCny] = [
	eventTimes[TimeLimitedQuestCode.Cny2024][0] - comingSoon,
	eventTimes[TimeLimitedQuestCode.Cny2024][0] - 1,
];
eventTimes[TimeLimitedQuestCode.ComingAprilFool] = [
	eventTimes[TimeLimitedQuestCode.AprilFool][0] - comingSoon,
	eventTimes[TimeLimitedQuestCode.AprilFool][1],
];
eventTimes[TimeLimitedQuestCode.ComingPirateDay] = [
	eventTimes[TimeLimitedQuestCode.PirateDay][0] - comingSoon,
	eventTimes[TimeLimitedQuestCode.PirateDay][0] - 1,
];
// const ended = 10000;
const ended = 3 * day;
eventTimes[TimeLimitedQuestCode.PostHalloween] = [
	eventTimes[TimeLimitedQuestCode.Halloween2024][1] + 1,
	eventTimes[TimeLimitedQuestCode.Halloween2024][1] + ended,
];
eventTimes[TimeLimitedQuestCode.PostXmas] = [
	eventTimes[TimeLimitedQuestCode.Xmas2022][1] + 1,
	eventTimes[TimeLimitedQuestCode.Xmas2022][1] + ended,
];
eventTimes[TimeLimitedQuestCode.PostCny] = [
	eventTimes[TimeLimitedQuestCode.Cny2024][1] + 1,
	eventTimes[TimeLimitedQuestCode.Cny2024][1] + ended,
];
eventTimes[TimeLimitedQuestCode.PostPirateDay] = [
	eventTimes[TimeLimitedQuestCode.PirateDay][1] + 1,
	eventTimes[TimeLimitedQuestCode.PirateDay][1] + ended,
];
// console.log(
// 	new Date(eventTimes[TimeLimitedQuestCode.PirateDay][0]),
// 	new Date(eventTimes[TimeLimitedQuestCode.PirateDay][1]),
// );
// console.log(
// 	new Date(eventTimes[TimeLimitedQuestCode.ComingPirateDay][0]),
// 	new Date(eventTimes[TimeLimitedQuestCode.ComingPirateDay][1]),
// );
// console.log(
// 	new Date(eventTimes[TimeLimitedQuestCode.PostPirateDay][0]),
// 	new Date(eventTimes[TimeLimitedQuestCode.PostPirateDay][1]),
// );

export function hasQuestTimeStarted(code: TimeLimitedQuestCode) {
	const ct = Date.now();
	const time = eventTimes[code];
	if (!time) { return false; }
	return ct >= time[0];
}
export function isQuestTime(code: TimeLimitedQuestCode) {
	const ct = Date.now();
	const time = eventTimes[code];
	if (!time) { return false; }
	return ct >= time[0]! && ct <= time[1];
}
export function isTimeQuestTime(ct: number, code: TimeLimitedQuestCode) {
	const time = eventTimes[code];
	if (!time) { return false; }
	return ct >= time[0]! && ct <= time[1];
}

export const timeLimitedQuests: Quest[] = [];

function setQuest(questTitle: string, questSteps: QuestStep[]) {
	const missionCode = questSteps[0].challenges[0].unlockId;
	for (let i = 0; i < questSteps.length; i++) {
		const req = questSteps[i].challenges;
		for (let k = 0; k < req.length; k++) {
			const cha = req[k];
			cha.isMaster = false;
			cha.step = i;
			cha.index = k;
			cha.unlockId = missionCode;
		}
	}
	timeLimitedQuests[missionCode] = {
		code: missionCode,
		title: questTitle,
		type: QuestType.TimeLimited,
		steps: questSteps,
		startTime: eventTimes[missionCode][0],
		endTime: eventTimes[missionCode][1],
	};
}

let steps: QuestStep[];
const index = 0;
let unlockId;
let title;
unlockId = TimeLimitedQuestCode.Xmas2021;
title = 'We Wish you a Stabby Christmas!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.SantaHat, amount: 3 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Send Love Emoji to Theodore the Polar Bear in the safe zone.`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpPresent, amount: 3 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 5,
				description: `Send Love Emoji to other players (with name) while wearing a Santa Hat x5`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpReindeerHeadband, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Send Love Emoji to your teammate while wearing a Santa Hat`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpSantaBeard, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.OneGame,
				goal: 100,
				description: `Jump out of the water and reach the star while wearing a Santa Hat.`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpRedNose, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 10000,
				description: `Score 10000 points while wearing a Reindeer Headband.`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpStarLantern, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.OneGame,
				goal: 100,
				description: `Jump out of the water and reach the star while wearing a Reindeer Headband and a Red Nose.`,
			},
		],
	},

];
setQuest(title, steps);

unlockId = TimeLimitedQuestCode.Xmas2022;
title = 'Let it Snow!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.BpXmasGlove, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Buy the Mercy Staff from Theodore the Polar Bear in the safe zone.`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpXmasBoxingGlove, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 5,
				description: `Release 5 of your stabbed victims using the Mercy Staff while wearing a Santa Hat`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.SantaBeard, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Jump into the air and release 3 stabbed victims at once while wearing a Santa Hat`,
			},
		],
	},
];
setQuest(title, steps);


unlockId = TimeLimitedQuestCode.Cny2023;
title = 'Stabby Lunar New Year!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.Money, amount: 8888 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Play the game during Lunar New Year event to enjoy double gold earning from loot!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.RedPacket, amount: 3 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Say 'Gong Xi Fa Cai' or 'Happy Lunar new Year' to Theodore the Polar Bear and the players in the safe zone!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpRabbitEarsHeadband, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Wear a Red Packet to enjoy 50% Additional Gold from loot during Lunar New Year Event!`,
			},
		],
	},

];
setQuest(title, steps);


// unlockId = TimeLimitedQuestCode.Halloween2023;
// title = 'Zompkin Attack!';
// steps = [
// 	{
// 		rewards: [{ itemCode: ItemCode.JackOLanternJaw, amount: 1 }],
// 		challenges: [
// 			{
// 				unlockId,
// 				index,
// 				step: 0,
// 				type: UnlockChallengeType.Accumulate,
// 				goal: 10,
// 				description: `Kill 10 Zompkin-infected fish!`,
// 			},
// 		],
// 	},
// 	{
// 		rewards: [{ itemCode: ItemCode.JackOLantern, amount: 1 }],
// 		challenges: [
// 			{
// 				unlockId,
// 				index,
// 				step: 0,
// 				type: UnlockChallengeType.Accumulate,
// 				goal: 20,
// 				description: `Infect 20 fish with Zompkin!`,
// 			},
// 		],
// 	},

// ];
// setQuest(title, steps);


unlockId = TimeLimitedQuestCode.XmasPenguin;
title = 'Penguin Dinner!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.Gem, amount: 15 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Boss,
				goal: 1,
				description: `Defeat 1★ Mechguin V-55`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.Gem, amount: 25 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Boss,
				goal: 1,
				description: `Defeat 2★ Mechguin V-55`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.Gem, amount: 50 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Boss,
				goal: 1,
				description: `Defeat 3★ Mechguin V-55`,
			},
		],
	},
];
setQuest(title, steps);

unlockId = TimeLimitedQuestCode.Cny2024;
title = 'Stabby Lunar New Year!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.Money, amount: 8888 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Play the game during Lunar New Year event to enjoy double gold earning from loot!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.RedPacket, amount: 3 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Say 'Gong Xi Fa Cai' or 'Happy Lunar new Year' to Theodore the Polar Bear and the players in the safe zone!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.GuapiCap, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Wear a Red Packet to enjoy 50% Additional Gold from loot during Lunar New Year Event!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BunCover, amount: 2 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 5,
				description: `Voluntarily take the golden yuanbao bait from the fishing Fish of Fortune 5 times`,
			},
		],
	},

];
setQuest(title, steps);

unlockId = TimeLimitedQuestCode.Halloween2024;
title = 'Zompkin Harvest!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.JackOLantern, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Kill a Zompkin!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.JackOLanternJaw, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Wear a Jack-O'-Lantern to repel Zompkin!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpOniMouthpiece, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 10,
				description: `Kill 10 Zompkins!`,
			},
		],
	},

];
setQuest(title, steps);

unlockId = TimeLimitedQuestCode.PirateDay;
title = 'International Talk Like a Pirate Day!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.Money, amount: 500 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Say 'Ahoy!' in chat to provoke a response from the Pirate Captain.`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.PirateBandana, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 5,
				description: `Kill 5 Pirate Crew Penguins!`,
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.PirateCaptainBicorne, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: `Be nearby when Macaroni Macguin meets his demise!`,
			},
		],
	},

];
setQuest(title, steps);
